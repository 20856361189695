import React, { Component } from "react";
import styled from "styled-components";
import logo from "../images/logo/gogig-logo.svg";
import { Avatar, AvatarURL } from "./Avatars.stories.js";
import NotificationIcon from "../images/icons/Notifications.svg";
import { NavLink } from "react-router-dom";
import { Popover, Menu, Position, Button } from "evergreen-ui";
import API from "API";
import { BrowserView, MobileOnlyView } from "react-device-detect";

export default {
  title: "Navigation",
};

const NavBarDivider = styled.div`
  width: 1px;
  background: silver;
  margin-left: 5px;
  margin-right: 5px;
  height: 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledNavBar = styled.div`
  background: white;
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
  padding: 15px;
  @media (max-width: 768px) {
    padding: 0px;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledNavBarItem = styled.p`
  color: black;
  font-size: 16px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 0;
  font-family: Roboto;
`;

const StyledNavBarLogoContainer = styled.div`
  margin-left: 25px;
  margin-right: 25px;
  img {
    position: relative;
    bottom: 0px;
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
    margin-top: 20px;
  }
`;

const StyledNavBarIconContainer = styled.div`
  margin-left: 25px;
  margin-right: 25px;
  cursor: pointer;
`;

const NavBarRightContainer = styled.div`
  display: flex;
  margin-left: auto;
  padding-left: 25px;
  padding-right: 25px;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: center;
    margin-left: 0;
    margin-bottom: 10px;
  }
`;

export const NavBar = () => <StyledNavBar />;

export const NavBarItem = ({ text }) => (
  <StyledNavBarItem>poop</StyledNavBarItem>
);

export const NavNotificationIcon = () => (
  <StyledNavBarIconContainer>
    <img
      src={require("../images/icons/Notifications.svg")}
      style={{
        width: 25,
        height: 25,
      }}
    />
  </StyledNavBarIconContainer>
);

export const NavBarLogo = () => (
  <StyledNavBarLogoContainer>
    <img src={logo} alt="Logo" style={{ width: 80 }} />
  </StyledNavBarLogoContainer>
);

export class NavBarWithItems extends Component {
  state = { avatar: localStorage.getItem("avatarSlug") || "" };


  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter[include]=avatar`
    ).then((response) => {
      this.setState({
        username: response.data.anonymousUsername,
        avatar: response.data.avatar.slug,
      });
    });
  }

  render() {
    let avatarPath = AvatarURL(this.state.avatar);
    return (
      <BrowserView>
        <StyledNavBar>
          <NavLink activeStyle={{ fontWeight: "bold" }} to="/">
            <NavBarLogo />
          </NavLink>
          <NavBarDivider />

          <NavLink activeStyle={{ fontWeight: "bold" }} to="/" exact>
            <StyledNavBarItem>Home</StyledNavBarItem>
          </NavLink>
          <NavLink activeStyle={{ fontWeight: "bold" }} to="/matches">
            <StyledNavBarItem>Matches</StyledNavBarItem>
          </NavLink>
          <NavLink activeStyle={{ fontWeight: "bold" }} to="/messages/unknown">
            <StyledNavBarItem>Messages</StyledNavBarItem>
          </NavLink>

          <NavBarRightContainer>
            <NavLink to="/notifications">
              <NavNotificationIcon />
            </NavLink>
            <Popover
              position={Position.BOTTOM_RIGHT}
              content={
                <Menu>
                  <Menu.Group>
                    <NavLink activeStyle={{ fontWeight: "bold" }} to="/profile">
                      <Menu.Item
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                          borderBottom: "1px solid silver",
                        }}
                      >
                        GoGig User {this.state.username}
                      </Menu.Item>
                    </NavLink>
                    <NavLink
                      activeStyle={{ fontWeight: "bold" }}
                      to="/settings/general"
                    >
                      <Menu.Item
                        style={{
                          color: "#000",
                        }}
                      >
                        General
                      </Menu.Item>
                    </NavLink>
                    <NavLink
                      activeStyle={{ fontWeight: "bold" }}
                      to="/feedback"
                    >
                      <Menu.Item
                        style={{
                          color: "#000",
                        }}
                      >
                        Feedback
                      </Menu.Item>
                    </NavLink>
                    <NavLink
                      activeStyle={{ fontWeight: "bold" }}
                      to="/settings/notifications"
                    >
                      <Menu.Item
                        style={{
                          color: "#000",
                        }}
                      >
                        Notifications
                      </Menu.Item>
                    </NavLink>
                    <NavLink
                      activeStyle={{ fontWeight: "bold" }}
                      to="/settings/profile"
                    >
                      <Menu.Item
                        style={{
                          color: "#000",
                        }}
                      >
                        Profile Photo
                      </Menu.Item>
                    </NavLink>
                    <NavLink activeStyle={{ fontWeight: "bold" }} to="/logout">
                      <Menu.Item
                        style={{
                          color: "#000",
                        }}
                      >
                        Logout
                      </Menu.Item>
                    </NavLink>
                  </Menu.Group>
                </Menu>
              }
            >
              <StyledEvergreenButton avatarPath={avatarPath} />
            </Popover>
          </NavBarRightContainer>
        </StyledNavBar>
      </BrowserView>
    );
  }
}

export class NavBarRecruiterWithItems extends Component {
  state = { avatar: "" };
  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter[include]=avatar`
    ).then((response) => {
      this.setState({
        avatar: response.data.avatar.slug,
      });
    });
  }
  render() {
    let avatarPath = AvatarURL(this.state.avatar);

    return (
      <StyledNavBar>
        <NavLink activeStyle={{ fontWeight: "bold" }} to="/recruiter/dashboard">
          <NavBarLogo />
        </NavLink>
        <NavBarDivider />
        <NavLink activeStyle={{ fontWeight: "bold" }} to="/recruiter/dashboard">
          <StyledNavBarItem>Dashboard</StyledNavBarItem>
        </NavLink>
        <NavLink activeStyle={{ fontWeight: "bold" }} to="/recruiter/searches">
          <StyledNavBarItem>Searches</StyledNavBarItem>
        </NavLink>
        <NavLink activeStyle={{ fontWeight: "bold" }} to="/recruiter/messages">
          <StyledNavBarItem>Messages</StyledNavBarItem>
        </NavLink>
        <NavBarRightContainer>
          {/* <NavNotificationIcon /> */}
          <Popover
            position={Position.BOTTOM_RIGHT}
            content={
              <Menu>
                <Menu.Group>
                  <NavLink activeStyle={{ fontWeight: "bold" }} to="/profile">
                    <Menu.Item
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        borderBottom: "1px solid silver",
                      }}
                    >
                      GoGig User {this.state.username}
                    </Menu.Item>
                  </NavLink>
                  <NavLink
                    activeStyle={{ fontWeight: "bold" }}
                    to="/settings/general"
                  >
                    <Menu.Item
                      style={{
                        color: "#000",
                      }}
                    >
                      General
                    </Menu.Item>
                  </NavLink>
                  <NavLink activeStyle={{ fontWeight: "bold" }} to="/feedback">
                    <Menu.Item
                      style={{
                        color: "#000",
                      }}
                    >
                      Feedback
                    </Menu.Item>
                  </NavLink>
                  <NavLink
                    activeStyle={{ fontWeight: "bold" }}
                    to="/settings/notifications"
                  >
                    <Menu.Item
                      style={{
                        color: "#000",
                      }}
                    >
                      Notifications
                    </Menu.Item>
                  </NavLink>
                  <NavLink
                    activeStyle={{ fontWeight: "bold" }}
                    to="/settings/profile"
                  >
                    <Menu.Item
                      style={{
                        color: "#000",
                      }}
                    >
                      Profile Photo
                    </Menu.Item>
                  </NavLink>
                  <NavLink activeStyle={{ fontWeight: "bold" }} to="/logout">
                    <Menu.Item
                      style={{
                        color: "#000",
                      }}
                    >
                      Logout
                    </Menu.Item>
                  </NavLink>
                </Menu.Group>
              </Menu>
            }
          >
            <StyledEvergreenButton avatarPath={avatarPath} />
          </Popover>
        </NavBarRightContainer>
      </StyledNavBar>
    );
  }
}

const StyledEvergreenButton = styled(Button)`
  margin-left: auto;
  border-radius: 50px;
  width: 38px !important;
  height: 38px !important;
  background-image: url(${(props) =>
    require("../" + props.avatarPath)}) !important;
  background-size: 38px 38px !important;
  background-repeat: no-repeat;
  :focus {
    background-color: white !important;
    outline: none !important;
  }
  :active {
    background-color: white !important;
    background-image: url(${(props) =>
      require("../" + props.avatarPath)}) !important;
  }
  :hover {
    background-color: white !important;
    background-image: url(${(props) =>
      require("../" + props.avatarPath)}) !important;
  }
  box-shadow: none !important;
`;
