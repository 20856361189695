import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import API from "API";
import styled from "styled-components";
import logo from "../images/logo/gogig-logo.svg";
import { BrowserView, MobileOnlyView } from "react-device-detect";

class RecruiterLogin extends Component {
  state = {
    email: "",
    password: "",
    error: null,
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleLogin = (e) => {
    e.preventDefault();
    API.noOptionsPost(`/UserProfiles/login`, {
      email: this.state.email,
      password: this.state.password,
    })
      .then((response) => {
        let stringifiedToken = JSON.stringify(response.data);
        localStorage.setItem("userToken", stringifiedToken);
        this.props.history.push("/recruiter/dashboard");
      })
      .catch((error) => {
        this.setState({
          error: error,
        });
        console.error(error);
      });
  };

  render() {
    return (
      <Container className="vh-100">
        <RegisterContainer>
          <RegisterContainerPane
            style={{
              backgroundColor: "#eceff0",
            }}
            className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block"
          >
            <StyledBanner
              src={require("images/modals/man-woman-vertical.png")}
            />
          </RegisterContainerPane>
          <RegisterContainerPane className="p-5">
            <StyledLogo src={logo} />
            <p className="text-muted text-center">Recruiter Mode</p>
            <button class="loginBtn loginBtn--facebook">
              Login With Facebook
            </button>
            <br />
            {this.state.error !== null && (
              <Alert variant="danger">
                Uh-oh. Something went wrong. Please double check your account
                information and submit again. Passwords must use at least one
                number, one uppercase letter, one lowercase letter, and one of
                the following characters: ! # $ % - _ = +
              </Alert>
            )}
            <Form className="mt-4">
              <Form.Group>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  onChange={this.handleChange}
                />
                <Form.Text className="text-muted">
                  Your email remains private
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  name="password"
                  onChange={this.handleChange}
                />
                <Form.Text className="text-muted">
                  6 or more characters
                </Form.Text>
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                onClick={this.handleLogin}
                className="d-block ml-auto mr-auto"
              >
                Log In as Recruiter
              </Button>
            </Form>
            <Link to="/reset-password">
              <p className="text-muted mt-3 text-center">
                Forgot password? Click here to reset it.
              </p>
            </Link>
            <Link to="/signup">
              <p className="text-center text-muted">
                Don't have an account? Register here.
              </p>
            </Link>
          </RegisterContainerPane>
        </RegisterContainer>
        <BrowserView>
          {/* <video id="background-video" loop autoPlay>
          <source src="videos/video.mp4" type="video/mp4" />
          <source src="videos/video.mp4" type="video/ogg" />
          Your browser does not support the video tag.
        </video> */}
        </BrowserView>
      </Container>
    );
  }
}

export default RecruiterLogin;

const RegisterContainer = styled.div`
  background: white;
  max-width: 800px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  z-index: 2;
  border-top: 10px solid #d30028;
`;

const StyledBanner = styled.img`
  width: 350px;
`;

const RegisterContainerPane = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledHeading = styled.h1`
  color: #0c3352;
  font-size: 48px !important;
  font-weight: bold;
`;

const StyledH3 = styled.h3`
  font-size: 18px;
  color: #000;
`;

const StyledLogo = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  display: block;
  margin-bottom: 0;
`;
