import React, { Component } from "react";
import API from "API";
import styled from "styled-components";
import { Link } from "react-router-dom";

class SearchesCard extends Component {
  state = {
    recruiterSearchQueries: [],
  };

  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}/recruiterSearchQueries`
    ).then((response) => {
      this.setState({
        recruiterSearchQueries: response.data,
      });
    });
  }

  render() {
    return (
      <SearchesCardContainer>
        <SearchesCardTitleContainer>
          <p>Searches</p>
        </SearchesCardTitleContainer>
        {this.state.recruiterSearchQueries.map((searchQuery) => (
          <SearchesCardItemContainer>
            <p>
              {searchQuery.searchTitle} | ${searchQuery.salaryMax}{" "}
            </p>
          </SearchesCardItemContainer>
        ))}
        <SearchesCardFooterContainer>
          <Link to="/recruiter/searches">
            <p>View All Searches</p>
          </Link>
        </SearchesCardFooterContainer>
      </SearchesCardContainer>
    );
  }
}

export default SearchesCard;

const SearchesCardContainer = styled.div`
  background: white;
  margin-top: 20px;
  border: 1px solid #f0f0f0;
  border-top: 5px solid #d30028;
  padding: 20px;
`;
const SearchesCardTitleContainer = styled.div`
  p {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.75px;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 10px;
    text-transform: uppercase;
    text-align: center;
  }
`;
const SearchesCardItemContainer = styled.div`
  padding: 5px;
  border-bottom: 1px solid #f5f5f5;
  p {
    text-align: center;
    font-size: 12px;
    color: #999;
    margin: 0;
    padding: 10px;
  }
`;
const SearchesCardFooterContainer = styled.div`
  p {
    text-align: center;
    color: #d30028;
    margin: 0;
    margin-top: 20px;
    font-size: 14px;
  }
`;
