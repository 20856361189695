import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import API from "API";
import styled from "styled-components";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

class DesiresModal extends Component {
  state = {
    jobPositions: [],
    jobPositionSpecialties: [],
    jobLevels: [],
    annualSalaryMin: 0,
    annualSalaryMax: 0,
    companyTypes: [],
    industries: [],
    remoteOk: false,
    desiredAnnualSalaryMin: 0,
    desiredAnnualSalaryMax: 0,
    desiredJobPosition: {},
    jobPositionJobPositionSpecialties: [],
    selectedJobPositionSpecialties: [],
    userDesiredJobLevel: {},
    userDesiredIndustry: {},
    userDesiredCompanyType: {},
    userDesiredJobLevel: {},
    remoteOk: false,
  };

  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter={
        "include": ["avatar", "userDesiredIndustry", "userDesiredCompanyType", "userDesiredJobLevel" ]
      }`
    ).then((response) => this.setState(response.data));

    API.get("/JobPositions").then((response) => {
      this.setState({
        jobPositions: response.data,
      });
    });

    API.get("/JobPositionSpecialties").then((response) => {
      this.setState({
        jobPositionSpecialties: response.data,
      });
    });

    API.get("/CompanyTypes").then((response) => {
      this.setState({
        companyTypes: response.data,
      });
    });

    API.get("/Industries").then((response) => {
      this.setState({
        industries: response.data,
      });
    });

    API.get("/JobLevels").then((response) => {
      this.setState({
        jobLevels: response.data,
      });
    });

    API.get(
      `/UserProfiles/${this.props.userToken.userId}/userDesiredJobPositionSpecialties?filter[include]=jobPositionSpecialty`
    ).then((response) => {
      let selectedJobPositionSpecialties = response.data.map(
        (jobPositionSpecialty) => {
          jobPositionSpecialty.name =
            jobPositionSpecialty.jobPositionSpecialty.name;
          return jobPositionSpecialty;
        }
      );

      this.setState({
        selectedJobPositionSpecialties,
      });
    });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    try {
      value = JSON.parse(value);
      this.setState({
        [name]: value,
      });
    } catch (e) {
      this.setState({
        [name]: value,
      });
    }
  };

  handleDesiredJobPosition = (e) => {
    let { name, value } = e.target;
    let jobPosition = JSON.parse(value);
    let jobPositionSpecialties = this.state.jobPositionSpecialties;
    let filteredJobPositionSpecialties = jobPositionSpecialties.filter(
      (jobPositionSpecialty) => {
        return jobPosition.id === jobPositionSpecialty.jobPositionId;
      }
    );
    this.setState({
      jobPositionJobPositionSpecialties: filteredJobPositionSpecialties,
      desiredJobPosition: jobPosition,
    });
  };

  handleAddJobPositionSpecialty = (e) => {
    let { name, value } = e.target;
    let jobPositionSpecialty = JSON.parse(value);
    jobPositionSpecialty.experience = 0;
    this.setState({
      selectedJobPositionSpecialties:
        this.state.selectedJobPositionSpecialties.concat(jobPositionSpecialty),
    });
  };

  screamChange = (v) => {
    if (v) {
      let selectedSpecialties = v.map((selectedSpecialty) => {
        return {
          ...selectedSpecialty.value,
          experience: 0,
        };
      });

      this.setState({
        selectedJobPositionSpecialties: selectedSpecialties,
      });
    }
  };

  handleRemoveSelectedJobPositionSpecialty = (jobPositionSpecialtyId) => {
    this.setState({
      selectedJobPositionSpecialties:
        this.state.selectedJobPositionSpecialties.filter(
          (jobPositionSpecialty) =>
            jobPositionSpecialty.id !== jobPositionSpecialtyId
        ),
    });
  };

  onSliderChange = (name, value) => {
    let min = value[0];
    let max = value[1];
    this.setState({
      desiredAnnualSalaryMin: min,
      desiredAnnualSalaryMax: max,
    });
  };

  onExperienceSliderChange = (sliderId, sliderValue) => {
    let selectedJobPositionSpecialties =
      this.state.selectedJobPositionSpecialties;
    let updatedJobPositionSpecialties = selectedJobPositionSpecialties.map(
      (jobPositionSpecialty) => {
        if (sliderId === jobPositionSpecialty.id) {
          jobPositionSpecialty.experience = sliderValue[1];
        }
        return jobPositionSpecialty;
      }
    );
    this.setState({
      selectedJobPositionSpecialties: updatedJobPositionSpecialties,
    });
  };

  handleSubmit = () => {
    API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
      userDesiredIndustryId: this.state.userDesiredIndustry.id,
      userDesiredCompanyTypeId: this.state.userDesiredCompanyType.id,
      userDesiredJobLevelId: this.state.userDesiredJobLevel.id,
      desiredAnnualSalaryMin: this.state.desiredAnnualSalaryMin,
      desiredAnnualSalaryMax: this.state.desiredAnnualSalaryMax,
      remoteOk: this.state.remoteOk,
    }).then((response) => {
      this.props.getFreshData();
      this.props.closeModal();
    });

    this.state.selectedJobPositionSpecialties.map((jobPositionSpecialty) => {
      if ("jobPositionSpecialtyId" in jobPositionSpecialty) {
        API.put(
          `/UserProfiles/${this.props.userToken.userId}/userDesiredJobPositionSpecialties/${jobPositionSpecialty.id}`,
          {
            experience: jobPositionSpecialty.experience,
          }
        );
      } else {
        API.post(
          `/UserProfiles/${this.props.userToken.userId}/userDesiredJobPositionSpecialties`,
          {
            jobPositionSpecialtyId: jobPositionSpecialty.id,
            experience: jobPositionSpecialty.experience,
          }
        );
      }
    });
  };

  render() {
    return (
      <Modal
        show={this.props.displayModal === "desires"}
        size="lg"
        animation={false}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <h1>Desires</h1>
                <p className="text-muted">
                  The data you enter here is the fuel to our matching algorithm.
                  Make sure you know say what you want here to help us match
                  your profile to the right recruiters.
                </p>
              </Col>
            </Row>
            <Form>
              <Form.Row>
                <Col>
                  <Form.Label>
                    What industry do you want to be working in?
                  </Form.Label>
                  <Form.Control
                    name="userDesiredIndustry"
                    onChange={this.handleChange}
                    as="select"
                    value={JSON.stringify(this.state.userDesiredIndustry)}
                  >
                    <option disabled value={JSON.stringify({})}>
                      -- select an option --
                    </option>
                    {this.state.industries.map((industry) => (
                      <option value={JSON.stringify(industry)}>
                        {industry.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Row>
              <Form.Row className="mt-2">
                <Col>
                  <Form.Label>
                    What type of company do you want to work at?
                  </Form.Label>
                  <Form.Control
                    name="userDesiredCompanyType"
                    as="select"
                    onChange={this.handleChange}
                    value={JSON.stringify(this.state.userDesiredCompanyType)}
                  >
                    <option disabled value={JSON.stringify({})}>
                      -- select an option --
                    </option>
                    {this.state.companyTypes.map((companyType) => (
                      <option value={JSON.stringify(companyType)}>
                        {companyType.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Row>
              <Form.Row className="mt-2">
                <Col>
                  <Form.Label>
                    Desired position type for your next career move
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="desiredJobPosition"
                    onChange={this.handleDesiredJobPosition}
                  >
                    <option selected value disabled>
                      -- select an option --
                    </option>
                    {this.state.jobPositions.map((jobPosition) => (
                      <option value={JSON.stringify(jobPosition)}>
                        {jobPosition.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Row>
              <Form.Row className="mt-2">
                <Col>
                  <Form.Label>
                    What specialties within "
                    {this.state.desiredJobPosition.name}" are you interested in?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="jobPositionSpecialty"
                    multiple
                  >
                    {this.state.jobPositionJobPositionSpecialties.map(
                      (jobPositionSpecialty) => (
                        <option
                          onDoubleClick={this.handleAddJobPositionSpecialty}
                          value={JSON.stringify(jobPositionSpecialty)}
                        >
                          {jobPositionSpecialty.name}
                        </option>
                      )
                    )}
                  </Form.Control>
                </Col>
              </Form.Row>
              {this.state.selectedJobPositionSpecialties.map(
                (jobPositionSpecialty) => (
                  <Form.Row className="mt-4">
                    <Form.Label>{jobPositionSpecialty.name}</Form.Label>
                    <Range
                      defaultValue={[0, jobPositionSpecialty.experience]}
                      min={0}
                      max={10}
                      name="slider1"
                      onChange={(d) =>
                        this.onExperienceSliderChange(
                          jobPositionSpecialty.id,
                          d
                        )
                      }
                      marks={{
                        0: "0",
                        1: "1 Year",
                        2: "2 Years",
                        3: "3 Years",
                        4: "4 Years",
                        5: "5 Years",
                        6: "6 Years",
                        7: "7 Years",
                        8: "8 Years",
                        9: "9 Years",
                        10: "10+ Years",
                      }}
                      tipFormatter={(value) => `${value}%`}
                      railStyle={{
                        backgroundColor: "#f5f5f5",
                      }}
                      trackStyle={[
                        { backgroundColor: "#D30028" },
                        { backgroundColor: "#D30028" },
                      ]}
                      handleStyle={[
                        {
                          backgroundColor: "#D30028",
                          borderColor: "#D30028",
                        },
                        {
                          backgroundColor: "#D30028",
                          borderColor: "#D30028",
                        },
                      ]}
                      dotStyle={{
                        backgroundColor: "#f5f5f5",
                      }}
                      activeDotStyle={{
                        backgroundColor: "#D30028",
                        borderColor: "#D30028",
                      }}
                    />
                  </Form.Row>
                )
              )}
              <Form.Row className="mt-5">
                <Col>
                  <Form.Label>
                    What level are you comfortable with working at?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="userDesiredJobLevel"
                    onChange={this.handleChange}
                    value={JSON.stringify(this.state.userDesiredJobLevel)}
                  >
                    <option disabled value={JSON.stringify({})}>
                      -- select an option --
                    </option>{" "}
                    {this.state.jobLevels.map((jobLevel) => (
                      <option value={JSON.stringify(jobLevel)}>
                        {jobLevel.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Row>
              <Form.Row className="mt-3">
                <Col>
                  <Form.Label>
                    How much salary are you targeting for your next career?
                  </Form.Label>
                  <Range
                    min={0}
                    max={200000}
                    onChange={(d) =>
                      this.onSliderChange("desiredAnnualSalary", d)
                    }
                    marks={{
                      0: "$0",
                      25000: "$25,000",
                      50000: "$50,000",
                      75000: "$75,000",
                      100000: "$100,000",
                      125000: "$125,000",
                      150000: "$150,000",
                      175000: "$175,000",
                      200000: "$200,000+",
                    }}
                    defaultValue={[
                      this.state.desiredAnnualSalaryMin,
                      this.state.desiredAnnualSalaryMax,
                    ]}
                    tipFormatter={(value) => `${value}%`}
                    railStyle={{
                      backgroundColor: "#f5f5f5",
                    }}
                    trackStyle={[
                      { backgroundColor: "#D30028" },
                      { backgroundColor: "#D30028" },
                    ]}
                    handleStyle={[
                      {
                        backgroundColor: "#D30028",
                        borderColor: "#D30028",
                      },
                      {
                        backgroundColor: "#D30028",
                        borderColor: "#D30028",
                      },
                    ]}
                    dotStyle={{
                      backgroundColor: "#f5f5f5",
                    }}
                    activeDotStyle={{
                      backgroundColor: "#D30028",
                      borderColor: "#D30028",
                    }}
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mt-4">
                <Col>
                  <Form.Group controlId="remoteOk">
                    <Form.Check
                      name="remoteOk"
                      onChange={() => {
                        this.setState({
                          remoteOk: !this.state.remoteOk,
                        });
                      }}
                      type="checkbox"
                      checked={this.state.remoteOk}
                      label="Are you willing to work remotely?"
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4">
                <Col>
                  <Button
                    variant="outline-primary"
                    className="mr-1"
                    onClick={this.props.closeModal}
                  >
                    Exit
                  </Button>
                  <Button className="float-right" onClick={this.handleSubmit}>
                    Update
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}

export default DesiresModal;

const JobPositionSpecialtyBubble = styled.div`
  background: red;
  margin: 5px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;
const JobPositionSpecialtyTitle = styled.p`
  margin: 0;
  padding: 0;
`;
const JobPositionSpecialtyRemove = styled.p`
  margin: 0;
  padding: 0;
  margin-left: 5px;
`;
