import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
  Alert,
} from "react-bootstrap";
import Select from "react-select";

import { RecruiterProfileCard } from "Stories/ProfileCard.stories";
import API from "API";
import { Link } from "react-router-dom";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { NavBarRecruiterWithItems } from "Stories/Navigation.stories.js";
import styled from "styled-components";
import PersonalitySnapshot from "PersonalitySnapshot";
import UserFeed from "UserFeed";
import RecruiterOnboarding from "RecruiterOnboarding";
import SearchesCard from "SearchesCard";

class RecruiterDashboardScreen extends Component {
  state = {
    recruiterEnabled: false,
  };

  componentDidMount() {
    API.get(`/UserProfiles/${this.props.userToken.userId}`)
      .then((response) => {
        this.setState(response.data);
      })
      .then(() => {
        if (!this.state.recruiterProfileId && this.state.recruiterEnabled) {
          API.post(`/RecruiterProfiles`, {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            companyName: this.state.companyName,
            jobTitle: this.state.jobTitle,
            companyEmail: this.state.companyEmail,
            phoneNumber: this.state.phoneNumber,
            linkedIn: this.state.linkedIn,
            userProfileId: this.props.userToken.userId,
          }).then((response) => {
            API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
              recruiterEnabled: true,
              recruiterProfileId: response.data.id,
              firstTimeRecruiterMode: false,
              newRecruiter: false,
            });
          });
        }
      });
  }

  render() {
    return (
      <Container
        style={{
          maxWidth: 1325,
        }}
      >
        <NavBarRecruiterWithItems {...this.props} />
        <Row>
          <Col md={3}>
            <RecruiterProfileCard {...this.props} />
          </Col>
          <Col>
            <div
              style={{
                marginTop: 20,
              }}
            ></div>
            <UserFeed recruiterMode={true} {...this.props} />
          </Col>
          <Col md={3}>
            <SearchesCard {...this.props} />
          </Col>
        </Row>
        <RecruiterOnboarding {...this.props} />
      </Container>
    );
  }
}

export default RecruiterDashboardScreen;
