import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Row, Button, Col, Container, Modal } from "react-bootstrap";
import styled from "styled-components";
import API from "API";

class DeleteModal extends Component {
  state = {
    searchTitle: "",
    deleteReason: "",
  };

  componentDidUpdate(prevProps) {
    if (prevProps.deleteSearchQueryId !== this.props.deleteSearchQueryId) {
      API.get(`/RecruiterSearchQueries/${this.props.deleteSearchQueryId}`).then(
        (response) => {
          this.setState(response.data);
        }
      );
    }
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleCancel = () => {
    this.props.handleModalShow("");
    this.props.handleEditMode(false);
  };

  handleDelete = () => {
    API.delete(
      `/UserProfiles/${this.state.userProfileId}/recruiterSearchQueries/${this.props.deleteSearchQueryId}`
    ).then((response) => {
      this.props.handleModalShow("");
      this.props.handleEditMode(false);
    });
  };

  render() {
    return (
      <Modal show={this.props.modalShow === "delete"} size="lg" centered>
        <Modal.Body>
          <ModalContainer>
            <ModalColumn>
              <img
                style={{
                  height: "100%",
                }}
                src={require("images/modals/man-thinking.png")}
              />
            </ModalColumn>
            <ModalColumn className="p-4 w-100">
              <h1>Confirm Deletion</h1>
              <p>
                Please confirm that you want to delete your{" "}
                {this.state.searchTitle} search query
              </p>
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Label>
                      Why are you deleting this GoGig Search?
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="deleteReason"
                      value={this.state.deleteReason}
                      onChange={this.handleChange}
                    >
                      <option disabled value="">
                        -- select an option --
                      </option>
                      <option value="1">
                        I've gotten the candidate I need from this GoGig Search
                      </option>
                      <option value="2">
                        I've gotten the candidate I need from somewhere besides
                        GoGig
                      </option>
                      <option value="3">
                        I don't need to hire for this position anymore for some
                        other reason
                      </option>
                      <option value="4">Other (please share)</option>
                    </Form.Control>
                  </Col>
                </Form.Row>
                <Form.Row>
                  {this.state.deleteReason === "4" && (
                    <Form.Control
                      className="p-3 mt-2"
                      as="textarea"
                      rows="12"
                      style={{
                        backgroundColor: "#F5F9FB",
                      }}
                      placeholder="Describe your experience here..."
                    />
                  )}
                </Form.Row>
                <Form.Row className="mt-3">
                  <Button
                    variant="outline-primary"
                    onClick={this.handleCancel}
                    className="mr-2"
                  >
                    Cancel
                  </Button>
                  <Button className="ml-auto" onClick={this.handleDelete}>
                    Delete
                  </Button>
                </Form.Row>
              </Form>
            </ModalColumn>
          </ModalContainer>
        </Modal.Body>
      </Modal>
    );
  }
}

export default DeleteModal;

const BackgroundImg = styled.div`
  width: 400px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://farm4.staticflickr.com/3902/14985871946_24f47d4b53_h.jpg");
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  margin: -16px;
`;
const ModalColumn = styled.div`
  justify-content: center;
`;
